import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faFacebook,
  faLinkedin,
  faXTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// Add to libarary
library.add(faFacebook, faXTwitter, faLinkedin);

const app = createApp(App);

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(store).use(router).mount("#app");
