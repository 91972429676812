<template>
  <header>
    <div class="logo"></div>
    <nav class="navbar">
      <router-link to="/">home</router-link>
      <router-link to="/apps">apps</router-link>
      <router-link to="/about">about</router-link>
    </nav>
  </header>
</template>
<style lang="scss" scoped>
@use "../../sass/utils/mixins" as *;
@use "../../sass/utils/grid" as *;

header {
  // background-color: yellow;
  position: absolute;
  z-index: 99;
  width: 100%;
  height: 58px;
  top: 2em;
  margin: 0 auto;
  padding: 0 1em;
  @include respond-to("medium") {
    padding: 0 5em;
  }
  @include flex-between;
  align-items: center;
  nav {
    height: 100%;
    @include flex-center;
    a {
      @include flex-center;
      margin: 0 0.2em;
      padding: 0.5em;
      border-radius: 1em;
      color: var(--text);
      @include respond-to("medium") {
        margin: 0 1em;
      }
      &.router-link-exact-active {
        background-color: var(--accent);
      }
      @include hover-device {
        &:hover {
          background-color: var(--accent);
        }
      }
    }
  }
}
</style>
