<template>
  <nav-bar></nav-bar>
  <router-view />
</template>
<script>
import NavBar from "./components/navigation/NavBar.vue";
export default {
  components: { NavBar },
};
</script>
<style lang="scss">
@use "./sass/base/typography";
@use "./sass/base/reset";

// nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }
</style>
